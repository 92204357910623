import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Location } from "../../Interfaces/Device/Location";
import {
  OpenErrorNotification,
  OpenInfoNotification,
  OpenSuccessNotification,
  OpenWarningNotification,
} from "../../Components/notification/Notification";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const getAllLocations = createAsyncThunk(
  "location/getLocations",
  async ({
    id,
    name,
    groups,
  }: { id?: number; name?: string; groups?: boolean } = {}) => {
    try {
      let searchString = "";
      if (id != null) searchString += `id=${id}`;
      if (name != null)
        searchString += `${searchString ? "&" : ""}name=${name}`;
      searchString += `${searchString ? "&" : ""}groups=true`;
      let response = await axios.get(`${API_URL}/Location?${searchString}`);

      return response.data;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const getAllLocationsType = createAsyncThunk(
  "LocationType",
  async ({ id, name }: { id?: number; name?: string } = {}) => {
    const searchString = `id${id && id > 0 ? "=" + id : ""}&name${
      name && name.length > 0 ? "=" + name : ""
    }`;
    try {
      let response = await axios
        .get(`${API_URL}/LocationType?${searchString}`)
        .then(async (response) => {
          return response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const searchLocations = createAsyncThunk(
  "location/setAllLocationNames",
  async ({
    searchString,
    namesOnly,
  }: {
    searchString: string;
    namesOnly?: boolean;
  }) => {
    try {
      let response = await axios
        .get(
          `${API_URL}/Location/search?${searchString}&namesonly=${namesOnly}`,
        )
        .then(async (response) => {
          return response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const searchLocationsFilter = createAsyncThunk(
  "location/searchLocations",
  async (searchObj: {
    id?: number;
    name?: string;
    orderbydesc?: boolean;
    enabled?: boolean;
    locationTypeId?: number;
    groups?: boolean;
  }) => {
    try {
      const params: Record<string, any> = {};
      if (searchObj.id !== undefined) params.id = searchObj.id;
      if (searchObj.name) params.name = searchObj.name;
      if (searchObj.orderbydesc !== undefined)
        params.orderbydesc = searchObj.orderbydesc;
      if (searchObj.enabled !== undefined) params.enabled = searchObj.enabled;
      if (searchObj.locationTypeId !== undefined)
        params.locationTypeId = searchObj.locationTypeId;
      if (searchObj.groups !== undefined) params.groups = searchObj.groups;
      if (params.locationTypeId == 0) {
        delete params.locationTypeId;
      }
      const response = await axios.get(`${API_URL}/Location`, { params });
      return response.data;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const postLocation = async ({
  StoreID,
  Enabled,
  Operating,
  Name,
  LocationTypeId,
  Description,
  Address,
  GPSLatitude,
  GPSLongitude,
  MediaId,
}: {
  StoreID: number;
  Enabled: boolean;
  Operating: boolean;
  Name: string;
  LocationTypeId: number;
  Description: string;
  Address: string;
  GPSLatitude: string;
  GPSLongitude: string;
  MediaId: number | null;
}) => {
  try {
    const response = await axios
      .post(`${API_URL}/Location`, {
        StoreID,
        Enabled,
        Operating,
        Name,
        LocationTypeId,
        Description,
        Address,
        GPSLatitude,
        GPSLongitude,
        MediaId,
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};

const patchLocationEnabled = async (id: number, enabled: boolean) => {
  try {
    let response = await axios
      .patch(`${API_URL}/Location/enabled`, { Id: id, Enabled: enabled })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};

export const patchLocation = createAsyncThunk(
  "locations/updateLocation",
  async (location: Location) => {
    let response = await axios
      .patch(`${API_URL}/Location`, location)
      .then(async (response) => {
        return response.data;
      })
      .catch((err: any) => {
        OpenErrorNotification(err.response.data.ErrorMessage);
        return err.response.data;
      });

    return response;
  },
);
export const patchLocationMedia = createAsyncThunk(
  "locations/updateLocationMedia",
  async (payload: { Id: number; MediaId: number }) => {
    try {
      let response = await axios.patch(`${API_URL}/Location/Media`, payload);

      return response.data;
    } catch (error: unknown) {
      return error;
    }
  },
);

const patchLocationGPS = async (
  id: number,
  latitude: string,
  longitude: string,
) => {
  try {
    let response = await axios
      .patch(`${API_URL}/Location/GPS`, {
        Id: id,
        Latitude: latitude,
        Longitude: longitude,
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};

const patchLocationType = async (id: number, locationTypeId: number) => {
  try {
    let response = await axios
      .patch(`${API_URL}/Location/locationType`, {
        Id: id,
        LocationTypeId: locationTypeId,
      })
      .then((response) => {
        return response.data;
      });

    return response;
  } catch (error: unknown) {
    return error;
  }
};

const deleteLocation = async (id: string) => {
  try {
    let response = await axios
      .delete(`${API_URL}/Location?id=${id}`)
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};

export const patchLocationAddress = createAsyncThunk(
  "locations/updateLocationAddress",
  async ({ id, address }: { id?: number; address?: string } = {}) => {
    try {
      OpenInfoNotification("Updating location address...");
      let searchString = "";
      if (id != null) searchString += `id=${id}`;
      const response = await axios.get(`${API_URL}/Location?${searchString}`);
      // Ensure that Result exists and is an object
      if (
        response.data &&
        typeof response.data.Result === "object" &&
        response.data.Result !== null
      ) {
        const updatedData = {
          ...response.data.Result[0],
          Address: address,
        };
        // Make a PATCH call to /Location with the updated data
        const patchResponse = await axios.patch(
          `${API_URL}/Location`,
          updatedData,
        );
        return patchResponse.data;
      } else {
        throw new Error("The response data is not in the expected format.");
      }
    } catch (error) {
      console.error("Error updating location data:", error);
      throw error;
    }
  },
);

export const patchLocationName = createAsyncThunk(
  "locations/updateLocationName",
  async ({ id, Name }: { id?: number; Name?: string } = {}) => {
    try {
      OpenInfoNotification("Updating location name...");
      let searchString = "";
      if (id != null) searchString += `id=${id}`;
      const response = await axios.get(`${API_URL}/Location?${searchString}`);

      if (
        response.data &&
        typeof response.data.Result === "object" &&
        response.data.Result !== null
      ) {
        const updatedData = {
          ...response.data.Result[0],
          Name: Name,
        };

        const patchResponse = await axios.patch(
          `${API_URL}/Location`,
          updatedData,
        );
        return patchResponse.data;
      } else {
        throw new Error("The response data is not in the expected format.");
      }
    } catch (error) {
      console.error("Error updating location data:", error);
      throw error;
    }
  },
);

export const locationSoftDelete = createAsyncThunk(
  "locations/softDelete",
  async (id: number) => {
    try {
      OpenInfoNotification("Deleting Location...");
      let searchString = `locationId=${id}`;
      const softDeleteResponse = await axios.patch(
        `${API_URL}/Location/softDelete?${searchString}`,
      );

      return softDeleteResponse.data;
    } catch (error) {
      console.error("Error deleting this location:", error);
      throw error;
    }
  },
);

export const locationArchive = createAsyncThunk(
  "locations/archive",
  async (id: number) => {
    try {
      OpenInfoNotification("Archiving Location...");
      let searchString = `locationId=${id}`;
      const archiveResponse = await axios.patch(
        `${API_URL}/Location/archive?${searchString}`,
      );

      return archiveResponse.data;
    } catch (error) {
      console.error("Error archiving this location:", error);
      throw error;
    }
  },
);

const patchGroup = async ({
  id,
  groupID,
}: { id?: number; groupID?: number } = {}) => {
  try {
    // First, get the current group data using the groupID
    let searchString = groupID != null ? `id=${groupID}` : "";
    const getResponse = await axios.get(`${API_URL}/Group?${searchString}`);

    // Prepared the request body for the PATCH call
    const patchRequestBody = {
      ...getResponse.data.Result[0], // spread the existing data
      LocationId: id, // overwrite or set the LocationId with the provided id
    };

    // Now, make the PATCH request to update the group data
    const patchResponse = await axios.patch(
      `${API_URL}/Group`,
      patchRequestBody,
    );
    return patchResponse.data;
  } catch (error) {
    console.error("Error updating group data:", error);
    throw error;
  }
};

const LocationService = {
  getAllLocationsType,
  getAllLocations,
  searchLocations,
  postLocation,
  patchLocation,
  patchLocationEnabled,
  patchLocationGPS,
  patchLocationType,
  deleteLocation,
  patchLocationAddress,
  patchLocationName,
  patchGroup,
  patchLocationMedia,
};

export default LocationService;
