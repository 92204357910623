import axios from "axios";
import { config } from "process";
import { URLSearchParams } from "url";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const getLastScannedSensorData = async (
  locationId?: number | null,
  deviceId?: string,
) => {
  try {
    const response = await axios
      .get(
        `${API_URL}/Graph/LastScannedSensorData?locationId=${
          locationId || ""
        }&deviceId=${deviceId || ""}`,
      )
      .then(async (response) => {
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};
export const getWeeklyScannedSensorData = async (
  locationId?: number | null,
  deviceId?: string,
) => {
  try {
    const response = await axios
      .get(
        `${API_URL}/Graph/WeeklyScannedSensorData?locationId=${
          locationId || ""
        }&deviceId=${deviceId || ""}`,
      )
      .then(async (response) => {
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};
export const exportSensorData = async (
  locationId?: number | null,
  deviceId?: string,
) => {
  try {
    const response = await axios({
      method: "get",
      url: `${API_URL}/Graph/ExportSensorDataToCsv?locationId=${
        locationId || ""
      }&deviceId=${deviceId || ""}`,
      responseType: "blob", // Set the response type to 'blob' to handle binary data
    }).then(async (response) => {
      return response.data;
    });
    return response;
  } catch (error: unknown) {
    return error;
  }
};

const dashboardService = {
  getLastScannedSensorData,
  getWeeklyScannedSensorData,
  exportSensorData,
};
export default dashboardService;
