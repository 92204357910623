import React, { ChangeEvent, FocusEvent, useEffect } from "react";
import {
  CustomInput,
  ErrorMsg,
  InputLabel,
  InputWrapper,
  SubLabel,
} from "./Input.style";
import { useField } from "formik";

export interface InputProps {
  width?: string;
  height?: string;
  label?: string;
  subLabel?: string;
  placeHolder?: string;
  field?: string;
  name: string;
  padding?: string;
  margin?: string;
  fontSize?: string;
  inputWrapperWidth?: string;
  value?: string;
  type?: string;
  disabled?: boolean;
  alignCenter?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  hasCustomErrorMessage?: boolean;
}

const Input: React.FC<InputProps> = ({
  width,
  height,
  label,
  placeHolder,
  subLabel,
  name,
  padding,
  margin,
  fontSize,
  inputWrapperWidth,
  value,
  type,
  disabled,
  alignCenter,
  onChange,
  onBlur,
  hasCustomErrorMessage,
}) => {
  const [field, meta] = useField(name);

  return (
    <InputWrapper
      padding={padding}
      fontSize={fontSize}
      width={inputWrapperWidth}
    >
      {label ? (
        <InputLabel htmlFor={name}>
          {label}
          <SubLabel>{subLabel ? subLabel : ""}</SubLabel>
        </InputLabel>
      ) : (
        ""
      )}
      <CustomInput
        disabled={!!disabled}
        alignCenter={alignCenter}
        id={name}
        name={name}
        placeholder={placeHolder}
        width={width}
        height={height}
        padding={padding}
        margin={margin}
        onBlur={onBlur}
        onChange={onChange || field.onChange}
        value={
          typeof field.value === "string"
            ? field.value.trimStart()
            : field.value
        }
        type={type}
        onKeyDown={(e) => {
          if (type == "number" && ["e", "E", "+", "-"].includes(e.key)) {
            e.preventDefault();
          }
        }}
      />
      {meta.touched && meta.error && !hasCustomErrorMessage && (
        <ErrorMsg>{meta.error}</ErrorMsg>
      )}
    </InputWrapper>
  );
};

export default Input;
